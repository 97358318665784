<template>

     <Modal 
            v-if="deleteModal.visible"
            :modal="deleteModal"
            @closeModal="deleteModal.visible = false"
            :cssClasses="{'is-full-height': true}"  
     >
      <template #header><span class="has-text-pink-1">DELETE ALL ITEMS FROM DATABASE?</span></template>
      <div class="has-text-light">ATTENTION: this cannot be reversed!<br>Are you sure you want to delete those items from the database?</div>
      <button class="button m-3 has-background-pink-1" @click="deleteAll">YES, DELETE</button>
      <button class="button m-3 has-background-green-3" @click="deleteModal.visible = false">CANCEL</button>
     </Modal>

      <Modal 
            v-if="listModal.visible"
            :modal="listModal"
            @closeModal="listModal.visible = false"
            :cssClasses="{'is-full-width': true,'is-full-height': true}"  
     >
      <template #header>EXPORT CSV LIST</template>
      <div class="has-text-light">
         What type of Spreadsheet/Table do you want to create?
      </div>
      
      <template #footer>
        <div class="is-flex is-full-width is-flex-wrap-wrap is-justify-content-center">
        <button class="button m-2 has-background-green-4" @click="createFullItemList">Full List</button>
        <button class="button m-2 has-background-green-4" @click="createCarnet">Carnet ATA (Customs)</button>
        <button class="button m-2 has-background-blue-4" @click="createVerwendungsnachweis">Verwendungsnachweis</button>
        <button class="button m-3 has-background-pink-1 has-text-white" @click="listModal.visible = false">CANCEL</button>
      </div>
         
      </template>
      
     </Modal>

     <Modal 
      v-if="handoverModal.visible"
      :modal="handoverModal"
      @closeModal="handoverModal.visible = false"
      :cssClasses="{'is-full-height': true}"  
  >
      <template v-slot:header>
          HAND OVER / LENT OUT ITEMS FROM RESERVATION
      </template>
      <template v-slot>
          <div class="has-background-light is-full-width is-full-height p-1">
              <h1 class="is-size-5 m-2">Enter (valid) username to handover to user</h1>
              <input v-model="handoverReceivingUser" class="input is-third-width" type="text" placeholder="userid of user to give item to">
              <button class="button has-background-pink-1 has-text-light mr-1" @click="handOutItemsFromBasket">HANDOVER</button>
              <h1 class="is-size-5 m-2">OR</h1>
              <button class="button has-background-pink-1 has-text-light" @click="handOutItemsFromBasket($event,true)">TAKE BACK ITEMS</button>      
          </div>
      </template>
      <template v-slot:footer>
          <button class="button has-background-pink-1 has-text-light" @click="handoverModal.visible = false">CANCEL</button>
      </template>
    </Modal>
    <div class="is-full-height is-flex is-flex-direction-column is-justify-content-end">
    <div>
        <h1 class="is-size-4">BASKET</h1>
    </div>
      <div class="basket-button-container">
        
          <div class="is-flex is-justify-content-right">
            <button class="button is-rounded has-background-pink-4" @click="exportBasketAsList"> 
              <Icon class="is-size-5 has-text-dark" :icon="['far', 'file-alt']"/>
            </button>

            <button class="button is-rounded" @click="basketMenuVisible=!basketMenuVisible">
              <Icon class="is-size-6 has-text-light" :icon="['fa', 'ellipsis-h']"/>
            </button>
          </div>
          

          <div v-if="basketMenuVisible" class="basketMenu is-flex is-flex-direction-column mt-3">
            <button class="button is-rounded is-size-7 has-background-dark has-text-white" @click="handleDeleteAll">DELETE ALL FROM DB</button>
            <button class="button is-rounded is-size-7 has-background-pink-1 has-text-white" @click="$router.push('/Reserve?itemsSource=basket')">RESERVE</button>
            <button class="button is-rounded is-size-7 has-background-pink-1 has-text-white" @click="handlehandOutItemsClicked">HANDOVER</button>
           <!-- <button class="button is-rounded is-size-7 has-background-pink-1 has-text-white" disabled>EDIT</button> -->
            <button class="button is-rounded is-size-7 has-background-pink-1 has-text-white" @click="$router.push('/Move?itemsSource=basket')">MOVE</button>
            <button class="button is-rounded is-size-7 has-background-pink-1 has-text-white" @click="exportBasketAsList">CREATE LIST</button>
            <button class="button is-rounded is-size-7 has-background-pink-1 has-text-white" 
              @click="$router.push('/QRCodeDisplay?singleItem=false')">QR CODES</button>
            <button class="button is-rounded is-size-7 has-background-blue-1 has-text-white" @click="$store.dispatch('emptyCart')">EMPTY BASKET</button>
          </div>
      </div>
      <div class="is-vertical-scrollable is-full-height mt-6">
        <div class="list-item-container" v-for="item in $store.state.data.basket" :key="item">
          <ListItem v-if="item" :item="item" @click="handleItemClicked($event,item)" :class="{'is-select-mode':selectModeOn, 'is-selected':selectedItems.find((i)=>i?.id == item?.id)}" :swipetoremove="true" @swipeRemove="handleSwipeRemove($event,item.id)"/>
        </div>
      </div>

      <div style="height: 11vh"></div>

    </div>
</template>

<script>

import {useStore} from 'vuex';
import { useRouter } from 'vue-router';
import ListItem from '@/components/Template/ListItem'
import Modal from '@/components/Template/Modal'
import { ref } from '@vue/reactivity'
import { ExportToCsv } from 'export-to-csv';

import Icon from '@/components/Template/Icon';
import { onMounted } from '@vue/runtime-core';

export default {
  components:{ListItem,Icon,Modal},
  setup() {
    const store = useStore();
    const router = useRouter();

    const selectModeOn = ref(false);
    const selectedItems = ref([]);

    const basketMenuVisible = ref(false);

    const deleteModal = ref({
        visible:false,
    })

    const listModal = ref({
        visible:false,
    })

    // FYI: `App.vue` includes a store subscription relevant to basket.

    onMounted(()=>{
      store.dispatch("showTabBar");
    })

    const handleSelectClicked = () =>{
      // activate SELECTION MODE
      selectModeOn.value = !selectModeOn.value;
      // empty selection
      selectedItems.value = [];
    } 

    const handleSwipeRemove = (e,id) =>{
      store.dispatch("removeItemsFromListById",{key:"basket",items:[{id}]})
    }

    const handleItemClicked = (event,item)=>{
      if (selectModeOn.value){
        return toggleItemSelect(item)
      }
      //store.dispatch("pickItem",item);
      router.push(`/Detail?id=${item.id}`);
    }

     function toggleItemSelect(item) {
      let index=selectedItems.value.findIndex((i) => i?.id===item?.id)
      if(index===-1) {
        selectedItems.value.push(item)
      }
      else {
        selectedItems.value.splice(index, 1)
      }
    }

    function handleDeleteAll(){
      deleteModal.value.visible = true;
    }

    function deleteAll(){
      store.dispatch('deleteItemsFromCart');
      deleteModal.value.visible = false;
    }

    function exportBasketAsList(){
      listModal.value.visible = true;
    }

    async function createFullItemList(){
       const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        //title: 'Dingsda2mex_FullItemList',
        filename: 'Dingsda2mex_FullItemList',
        headers: ["#","id","name","description","count","weight","value","price","length","width","depth","countryoforigin","billigreference","invoicedate","location.insideofarray","insideof","tags","pictures","owned","visible2","possessedBy","lastmodified","lastmodifiedby","location.placename","location.id","location.geoJSON"]
      };

      const csvExporter = new ExportToCsv(options);
 
      let listData = store.state.data.basket.map((row,index)=>{
        return [ index+1 ].concat(options.headers.filter((key,ind)=>{return ind != 0})
        .map((key)=>{
          if (!row[key]){return ""}
          if (Array.isArray(row[key])){return row[key].join(", ")}
          return row[key]
        }))
      })

      csvExporter.generateCsv(listData);
    }

    async function createCarnet(){
      const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        title: 'Carnet ATA',
        filename: 'Carnet_ATA',
        headers: ["Item No.","Trade description of goods and marks and numbers if any","Number of Pieces", "Weight or Volume","Value","Country of Origin"]
      };

      const csvExporter = new ExportToCsv(options);
 
      let carnetData = store.state.data.basket.map((row,index)=>{return [ index+1, row.name, row.count, row.weight+" kg", row.value.replace("$",""), row.countryoforigin || '']})

      csvExporter.generateCsv(carnetData);
    }

    function createVerwendungsnachweis(){
      const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        title: 'Verwendungsnachweis',
        filename: 'Verwendungsnachweis',
        headers: ["Laufnr.","Leistung","Belegnr.","Ist-Summe","Belegdatum"]
      };

      const csvExporter = new ExportToCsv(options);

      let verwendungsnachweisData = store.state.data.basket.map((row,index)=>{return [ index+1, row.name, row.billingreference || '', row.price.replace("$","") || '', row.invoicedate || '']})

      csvExporter.generateCsv(verwendungsnachweisData);
    }


    const handoverReceivingUser = ref(null);
    const handoverModal = ref({visible:false})
    let handoverItems = [];

    async function handlehandOutItemsClicked(){
      handoverModal.value.visible = true;
      handoverItems = Object.values(store.state.data.basket).map((entry) => {
        console.log(entry);
       return entry.id
      }
        );
      console.log("handoverItems",handoverItems);
    }

    const handOutItemsFromBasket = async (e,takeback)=>{
      console.log(e,takeback);
      let handOverto = [handoverReceivingUser.value]
      if (takeback){handOverto=[]}
      if (!takeback && !handoverReceivingUser.value){
        return store.dispatch('triggerToast',{message:'username must be provided!'})
      }
      let result = await store.dispatch('handoverItems',{item_ids:handoverItems,to:handOverto})
      if (result) {
        handoverModal.value.visible = false;
        refreshBasket();
      }
    }

    const refreshBasket = ()=>{
      store.dispatch('refreshBasket')
    }

   return {
      handleDeleteAll,
      handleSelectClicked,
      handleItemClicked,
      selectModeOn,
      selectedItems,
      basketMenuVisible,
      deleteModal,
      deleteAll,
      handleSwipeRemove,
      exportBasketAsList,
      listModal, createCarnet,createVerwendungsnachweis,createFullItemList,
      handoverModal,handoverReceivingUser,handlehandOutItemsClicked,handOutItemsFromBasket
      }
  },
}
</script>